'use client';

import React, {useEffect} from "react";
import {useStickyFooterAdStore, useStickyHeaderStore} from "@/lib/stores/layout";
import {useThrottle, useWindowScroll} from "@uidotdev/usehooks";

export default function Utils() {
  return (
    <>
      <Utils.StickyHeader/>
      <Utils.StickyFooterAd/>
    </>
  );
}

Utils.StickyHeader = function () {
  const stickyState = useStickyHeaderStore();
  const [{y}] = ('undefined' !== typeof window) ? useWindowScroll() : [{y: 0}];
  const shouldHeaderSticky = y > 150;
  /*const width = useThrottledWindowWidth();*/

  /*useEffect(() => {
    stickyState.setSticky(shouldHeaderSticky && width >= 768);
  }, [shouldHeaderSticky, width]);*/

  useEffect(() => {
    stickyState.setSticky(shouldHeaderSticky);
  }, [shouldHeaderSticky]);
};

Utils.StickyFooterAd = function () {
  const setVisibility = useStickyFooterAdStore(state => state.setVisibility);
  const [{y}] = ('undefined' !== typeof window) ? useWindowScroll() : [{y: 0}];
  const throttledWindowScrollTop = useThrottle(y, 100);

  // Toggle sticky footer ad
  useEffect(() => {
    setVisibility(throttledWindowScrollTop > 300);
  }, [throttledWindowScrollTop]);
};
